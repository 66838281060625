import { useRef, useEffect, ChangeEvent, MouseEvent, TouchEvent, WheelEvent} from "react";

function ImageZoom (props : {src : string, alt : string, fullHeight: number, fullWidth: number}) {
  let isMouseDown = false;
  let mousedownX : number;
  let mousedownY : number;
  let mouseX : number;
  let mouseY : number;

  let isTouch = false;
  let touchSeparation : number;
  let touchX : number;
  let touchY : number;

  const image = useRef<HTMLImageElement>(null);
  const frame = useRef<HTMLDivElement>(null);
  const slider = useRef<HTMLInputElement>(null);

  let zoomMin : number;
  let zoom : number;

  useEffect(() => {
    if (frame.current?.offsetHeight !== undefined && image.current?.offsetHeight !== undefined && slider.current?.offsetHeight !== undefined){
      zoomMin = Math.min(frame.current?.offsetHeight / props.fullHeight, frame.current?.offsetWidth / props.fullWidth);
      slider.current.min = `${zoomMin}`;
      slider.current.value = `${zoomMin}`;
      image.current.height = props.fullHeight * zoomMin;
      image.current.width = props.fullWidth * zoomMin;
      zoom = zoomMin;

      image.current.addEventListener('wheel', (e) => {
        e.preventDefault();
        setZoom(zoom - 0.001*e.deltaY)
      })
      return(image.current.removeEventListener('wheel', (e) => {
        e.preventDefault();
        setZoom(zoom - 0.001*e.deltaY)
      }))

    }
  }, [props.fullHeight]);

  const mouseDown = (e : MouseEvent<HTMLImageElement>) => {
    isMouseDown = true;
    mousedownX = e.pageX;
    mousedownY = e.pageY;
    mouseX = mousedownX;
    mouseY = mousedownY;
  }

  const mouseMove = (e : MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    if(!isMouseDown) {return;}
    if (frame.current != null) {
      frame.current.scrollLeft += mouseX - e.pageX;
      frame.current.scrollTop += mouseY - e.pageY;
    }
    mouseX = e.pageX;
    mouseY = e.pageY;
    if (image.current != null) {
      image.current.style.cursor = "grabbing";
    }
  }

  const mouseUp = (e : MouseEvent<HTMLImageElement>) => {
    isMouseDown = false;
    if (image.current != null) {
      image.current.style.cursor = "grab";
    }
  }

  const mouseLeave = (e : MouseEvent<HTMLImageElement>) => {
    isMouseDown = false;
    if (image.current != null) {
      image.current.style.cursor = "grab";
    }
  }

  const touchStart = (e : TouchEvent<HTMLImageElement>) => {
    isTouch = true;
    let touchStartX = 0;
    let touchStartY = 0;
    if (e.touches.length === 1) {
      touchStartX = e.touches[0].pageX;
      touchStartY = e.touches[0].pageY;
    }
    else {
      touchStartX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
      touchStartY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
      touchSeparation = ((e.touches[0].pageX - e.touches[1].pageX) ** 2 + (e.touches[0].pageY - e.touches[1].pageY) ** 2) ** 0.5; 
    }
    touchX = touchStartX;
    touchY = touchStartY;
  }

  
  const touchMove = (e : TouchEvent<HTMLImageElement>) => {
    //e.preventDefault();
    if(!isTouch) {return;}
    let changeX = 0;
    let changeY = 0;
    let changeSeparation;
    if (e.touches.length === 1) {
      changeX = e.touches[0].pageX;
      changeY = e.touches[0].pageY;
    }
    else {
      changeX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
      changeY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
      changeSeparation = ((e.touches[0].pageX - e.touches[1].pageX) ** 2 + (e.touches[0].pageY - e.touches[1].pageY) ** 2) ** 0.5; 
      
      setZoom(zoom * changeSeparation/touchSeparation)
      touchSeparation = changeSeparation;
    }

    if (frame.current != null) {
      frame.current.scrollLeft += (touchX - changeX);
      frame.current.scrollTop += (touchY - changeY);
    }
    touchX = changeX;
    touchY = changeY;
  }

  const touchEnd = (e : TouchEvent<HTMLImageElement>) => {
    isTouch = false;
  }

  const setZoom = (zoomTo : number) => {
    if (frame.current != null && image.current != null && slider.current != null) {
      let imageCenterX = (frame.current.offsetWidth / 2 + frame.current.scrollLeft) / zoom;
      let imageCenterY = (frame.current.offsetHeight / 2 + frame.current.scrollTop) / zoom;
      if (zoomTo > 1) {zoom = 1;}
      else if (zoomTo < Number(slider.current.min)) {zoom = Number(slider.current.min);}
      else {zoom = zoomTo;}
      image.current.height = image.current.naturalHeight * zoom;
      image.current.width = image.current.naturalWidth * zoom;
      slider.current.value = `${zoom}`;
      frame.current.scrollLeft = imageCenterX * zoom - frame.current.offsetWidth / 2
      frame.current.scrollTop = imageCenterY * zoom - frame.current.offsetHeight / 2
    }
  } 

  const handleZoom = (e: ChangeEvent<HTMLInputElement>) => {
    setZoom(Number(e.currentTarget.value))
  }

  return (
  <>
    
    <div className="p-2" style={{position: "relative", backgroundColor: "yellow", borderRadius: "25px", border: "2px solid black"}}>
      <div className="text-center" style={{position: "absolute", top: "10px", left: "50%", margin: "auto", transform: "translate(-100px, 0)"}}>
        <input id="zoom-slider" type="range" min="0" max="1" step="0.01" ref={slider} defaultValue="0" onChange={handleZoom}/>
      </div>
      <div ref={frame} className="p-0" style={{display: "flex", height: "min(calc(100vh - 124px), 100vw", overflow: "hidden", maxWidth: "100%", borderRadius: "25px"}}>
        <img ref={image} alt={props.alt} draggable="false" src={props.src} style={{touchAction: "none", display: "block", margin: "auto"}}
             onMouseDown={mouseDown}
             onMouseMove={mouseMove}
             onMouseUp={mouseUp}
             onMouseLeave={mouseLeave}
             onTouchStart={touchStart}
             onTouchMove={touchMove}
             onTouchEnd={touchEnd}
        />
      </div>
    </div>
  </>
  );
}

export default ImageZoom;

