import {Container, Image, Navbar} from "react-bootstrap";
import MainHeaderTransparent from "../../resources/MainHeaderTransparent.png";
import Nav from "react-bootstrap/Nav";

function SiteNavbar() {

    return (
        <Navbar bg={"secondary"} expand={"lg"} sticky={"top"}>
            <Container style={{fontFamily: "sans-serif", fontWeight: "bold", alignItems: "center", minHeight: "80px", margin: "0px 12px"}} fluid>
                <Navbar.Brand href={"/home"} className="d-flex align-items-center" style={{height: "80px"}}>
                    <Image src={MainHeaderTransparent} alt="Logo" style={{height: "70px", maxHeight:"12vw", marginRight: "10px"}}/>
                </Navbar.Brand>
                <a href="https://www.justgiving.com/page/auto-trader-24-hour" id="navbar-donate-button-mobile" className="navbar-donate-button">Donate</a>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
                    <Nav>
                        <Nav.Item>
                            <Nav.Link href={"/home"}>Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"/get-involved"}>Get Involved</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"/about-us"}>About Us</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"https://www.the24hourrun.co.uk/"}>Franchise Events</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"/press"}>In the Press</Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>*/}
                        {/*    <Nav.Link href={"/virtual-tracker"}>Virtual Tracker</Nav.Link>*/}
                        {/*</Nav.Item>*/}
                        <Nav.Item>
                            <Nav.Link href={"/gallery"}>Gallery</Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>
                            <Nav.Link href={"/merch"}>Merch</Nav.Link>
                        </Nav.Item>*/}
                        {/*<Nav.Item>*/}
                        {/*    <Nav.Link href={"/livestream"}>Livestream</Nav.Link>*/}
                        {/*</Nav.Item>*/}
                        <Nav.Item>
                            <Nav.Link href={"/contact"}>Contact</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <a id="navbar-donate-button-main" className="navbar-donate-button" href={"https://www.justgiving.com/page/auto-trader-24-hour"}>Donate</a>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default SiteNavbar;