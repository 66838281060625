import {Button, Card, Col, Container, Ratio, Row} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";
import FundraisingBar from "../components/fundraising-bar";

function HomePage() {

    return (
        <Container fluid>
            <Row style={{height: "200px"}}>
                <FundraisingBar />
            </Row>
            <Row className="landing-screen">
                <Container>
                    <Row>
                        <Col md>
                            <Button className="mt-5 fs-2 w-75" as="a" href="/get-involved" variant="primary" size="lg">
                                Get Involved
                            </Button>
                        </Col>
                        <Col md>
                            <Button className="mt-5 fs-2 w-75" as="a" href="/about-us" variant="primary">
                                About Us
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>

            <Row><div className="divider div-transparent div-dot mb-3 mt-5"></div></Row>

            <Row className="my-3 py-4 justify-content-center">
                <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
                    <Row>
                        <h1 className="fs-2 fw-bold">The Manchester 24 Hour Run Against Homelessness 2024</h1>
                    </Row>
                    <Row className="fw-bold justify-content-center">
                        <Col sm={8} md={6} className="mt-3 justify-content-center">
                            <ElevatedCard colour="#FFA500" elevation={4}>
                                <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Wednesday 13th - Thursday 14th November 2024"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                        <Col sm={8} md={6} className="mt-3">
                            <ElevatedCard colour="#FFCC00" elevation={4}>
                                <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"Location: Freight Island, 11 Baring St, Manchester M1 2PY"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                    </Row>
                    <Row>
                        <p className="fs-5 text-center mt-4">{"The Manchester 24 Hour Run Against Homelessness is a mass participation group relay run that is held over 24 hours. Runners and walkers set off together in waves to complete 5km laps in the city centre of Manchester, all to raise money for the Greater Manchester Mayor's Charity scheme 'A Bed Every Night'."}</p>
                    </Row>
                    <Row xs="auto" style={{justifyContent: "center"}}>
                        <Button className="mt-3 fs-3 px-3 py-2" as="a" href="/get-involved" variant="primary">
                            Find out more
                        </Button>
                    </Row>             
                </Col>
            </Row>

            <Row><div className="divider div-transparent div-dot mb-5"></div></Row>

            {/*<Row className="py-4 justify-content-center">*/}
            {/*    <Col className="mb-5 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>*/}
            {/*        <Row>*/}
            {/*            <ElevatedCard colour="#FFCC00" elevation={4}>*/}
            {/*                <Card.Body>*/}
            {/*                    <h1 className="fs-2 fw-bold">More information coming in 2024!</h1>*/}
            {/*                </Card.Body>*/}
            {/*            </ElevatedCard>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            {/*<Row><div className="divider div-transparent div-dot mb-5"></div></Row>*/}

            <Row>
                <Ratio aspectRatio={"16x9"}>
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/OM2tBHLgwJc"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </Ratio>
            </Row>

        </Container>
    );
}

export default HomePage;