import React, { useState } from 'react';
import {LAP_TRACKER_URL} from "./LapCounterWidget";
import {Alert, Button, Container, Form} from "react-bootstrap";

const SubmitLapWidget: React.FC = () => {
    // Form data state
    const [numRunners, setNumRunners] = useState<number | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Validate input
        if (!numRunners) {
            setError('Please provide the number of runners.');
            return;
        }

        setLoading(true);
        setError(null); // Reset previous errors
        setSuccessMessage(null); // Reset previous success message

        try {
            // Send data to the /add-next-lap endpoint
            const response = await fetch(`${LAP_TRACKER_URL}/add-next-lap`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420', // Add custom header
                },
                body: JSON.stringify({
                    runners: numRunners,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit the lap data');
            }

            const result = await response.json();
            console.log(result);

            setSuccessMessage('Lap data successfully submitted!');
            // Reset form fields after successful submission
            setNumRunners(0);
        } catch (error: any) {
            setError(error.message || 'An error occurred while submitting the lap data');
        } finally {
            setLoading(false);
        }
    };

    // Handle input change with min value enforcement
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Allow backspace and custom input, ensure the value doesn't go below 0
        if (value === '' || /^[0-9]*$/.test(value)) {
            setNumRunners(+value);
        }
    };

    return (
        <Container className="mt-5">
            <h1>Submit Next Lap</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="numRunners">
                    <Form.Label>Number of Runners</Form.Label>
                    <Form.Control
                        type="number"
                        value={numRunners}
                        onChange={handleInputChange}
                        required
                        placeholder="Enter the number of runners"
                        min={0}
                    />
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={loading}
                    className="mt-5"
                >
                    {loading ? 'Submitting...' : 'Submit Lap'}
                </Button>
            </Form>

            {/* Error and success messages */}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
        </Container>
    );
};

export default SubmitLapWidget;