import SiteNavbar from "./ts/components/SiteNavbar";
import Footer from "./ts/components/Footer";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import HomePage from "./ts/home/HomePage";
import AboutPage from "./ts/about/AboutPage";
import GetInvolvedPage from "./ts/get-involved/GetInvolvedPage";
import GalleryPage from "./ts/gallery/GalleryPage";
import ContactPage from "./ts/contact/ContactPage";
import MainEventSubPage from "./ts/get-involved/MainEventSubPage";
import ShakeoutSubPage from './ts/get-involved/ShakeoutSubPage';
import ParkrunSubPage from './ts/get-involved/ParkrunSubPage';
//import MerchPage from "./ts/merch/MerchPage";
import VirtualTracker from "./ts/virtual-tracker/VirtualTracker";
import LivePage from "./ts/live/live";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import LivestreamPage from "./ts/livestream/LivestreamPage";
import CountdownWidget from "./ts/live/CountdownWidget";
import FundraisingBar from "./ts/components/fundraising-bar";
import LapCounterWidget from "./ts/live/LapCounterWidget";
import PostEventHomePage from "./ts/home/PostEventHomePage";
import {PressPage} from "./ts/press/PressPage";
import SubmitLapWidget from "./ts/live/SubmitLapWidget";

//Google Analytics
const TRACKING_ID = "G-SVT3R0T3QX";
ReactGA.initialize(TRACKING_ID);

const NavLayout = () => {
    return (
        <>
            <SiteNavbar/>
            <Outlet/>
            <Footer/>
        </>
    );
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
      <>
          <BrowserRouter>
              <Routes>
                  <Route element={<NavLayout />} >
                      <Route index element={<HomePage/>} />
                      <Route path={"/home"} element={<HomePage/>} />
                      <Route path={"/about-us"} element={<AboutPage/>} />
                      <Route path={"/get-involved"} element={<GetInvolvedPage/>}>
                          <Route path={"main-event"} element={<MainEventSubPage/>}/>
                          <Route path={"5k-shakeout"} element={<ShakeoutSubPage/>}/>
                          <Route path={"parkrun-meetup"} element={<ParkrunSubPage/>}/>
                      </Route>
                      {/*<Route path={"/virtual-tracker"} element={<VirtualTracker isSubmit={false} />} />*/}
                      {/*<Route path={"/virtual-tracker/submit"} element={<VirtualTracker isSubmit={true} />} />*/}
                      <Route path={"/press"} element={<PressPage />} />
                      <Route path={"/gallery"} element={<GalleryPage/>} />
                      {/*<Route path={"/merch"} element={<ComingSoon/>} />*/}
                      <Route path={"/livestream"} element={<LivestreamPage/>} />
                      <Route path={"/contact"} element={<ContactPage/>} />
                      <Route path={"/latest"} element={<LivePage/>} />
                      <Route path={"/post-event"} element={<PostEventHomePage/>} />
                  </Route>

                  <Route path={"/widgets/countdown"} element={<CountdownWidget/>} />
                  <Route path={"/widgets/total"} element={<FundraisingBar />} />
                  <Route path={"/widgets/lap-counter"} element={<LapCounterWidget />} />
                  <Route path={"/widgets/submit-lap"} element={<SubmitLapWidget />} />
              </Routes>
          </BrowserRouter>
      </>
  );
}

export default App;
