import Countdown from "react-countdown";

interface timeFormat {
    hours: number,
    minutes: number,
    seconds: number,
}

const pad = (n: number) => n < 10 ? `0${n}` : n;

const renderer = ({ hours, minutes, seconds }: timeFormat) => {
    return <span style={{fontWeight: "bold", fontSize: 36}}>{`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`}</span>;
};

const CountdownWidget = () => {
    return (
        <>
            <span style={{fontWeight: "bold", fontSize: 21}}>Time Remaining</span>
            <Countdown
                renderer={renderer}
                date={new Date("2024-11-14T12:00:00.000Z")}
            />
        </>
    );
}

export default CountdownWidget;