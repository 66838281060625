import {Container} from "react-bootstrap";
import React, {useEffect, useState} from "react";

export const LAP_TRACKER_URL = "https://fit-seemingly-wahoo.ngrok-free.app";

interface LapData {
    latestLap: number,
    totalRunners: number,
    latestRunners: number,
    totalDistance: number,
}

const LapCounterWidget: React.FC = () => {
    const [latestLapData, setLatestLapData] = useState<LapData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchData = async () => {
        try {
            const response = await fetch(`${LAP_TRACKER_URL}/latest`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', // Ensure the correct content type is set
                    'ngrok-skip-browser-warning': '69420', // Add your custom header here
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch latestLapData');
            }

            const result: LapData = await response.json();
            setLatestLapData(result); // Update state with the latest latestLapData
            setLoading(false); // Set loading to false after latestLapData is fetched
        } catch (error: any) {
            setError(error.message || 'An error occurred');
            setLoading(false); // Stop loading even if there's an error
        }
    };

    useEffect(() => {
        // Fetch latestLapData initially when the component mounts
        fetchData();

        // Set an interval to fetch latestLapData every 10 seconds (10000 milliseconds)
        const intervalId = setInterval(fetchData, 30000); // Adjust the interval as needed

        // Cleanup the interval when the component unmounts to avoid memory leaks
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (loading) {
        return (
            <Container>
                <h1>Lap Number: -</h1>
                <h1>Total Runners: -</h1>
                <h1>Total Distance: -km</h1>
            </Container>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (latestLapData) return (
        <Container>
            <h1 className="fw-bold text-white" style={{fontSize: 50}}>Lap Number: <span className="text-primary">{latestLapData.latestLap}</span></h1>
            <h1 className="fw-bold text-white" style={{fontSize: 50}}>Total Runners: <span className="text-primary">{latestLapData.totalRunners}</span></h1>
            <h1 className="fw-bold text-white" style={{fontSize: 50}}>Total Distance: <span className="text-primary">{latestLapData.totalDistance}km</span></h1>
        </Container>
    );

    return (
        <Container>
            <h1>Lap Number: -</h1>
            <h1>Total Runners: -</h1>
            <h1>Total Distance: -km</h1>
        </Container>
    );
}

export default LapCounterWidget;