import {Container, Ratio, Row} from "react-bootstrap";
import PageHeading from "../utils/PageHeading";
import YellowBackgroundImage from "../../resources/YellowBackgroundHexs.png";

const LivestreamPage = () => {
    return (
        <Container fluid style={{backgroundImage: `url(${YellowBackgroundImage})`, fontFamily: "sans-serif", flexGrow: "1", flexShrink: "0", flexBasis: "auto"}}>
            <Row>
                <PageHeading>Livestream</PageHeading>
            </Row>
            <Row>
                <Ratio aspectRatio={"16x9"}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vMn9Pp-J6V4?si=vb8Ad0geDFtDjTsH"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </Ratio>
            </Row>
            <Row className="d-md-flex d-lg-none flex-grow-1" style={{height: "25vh", flexGrow: "1"}}>
                <Row style={{ backgroundSize: "cover", backgroundAttachment: "fixed", backgroundPosition:"center", margin: "0px", alignItems: "center"}} fluid>
                    <Container style={{width: "auto"}}>
                        <a id="navbar-donate-button-livestream" href={"https://www.justgiving.com/page/mcr-24-hour-run-2024"} style={{
                            display: "flex",
                            padding: "8px 12px",
                            borderRadius: "5px",
                            backgroundColor: "#9e0b85",
                            textDecoration: "none",
                            color: "white",
                            fontFamily: "Versa Versa",
                            fontSize: "xx-large"
                        }}>Donate</a>
                    </Container>
                </Row>
            </Row>
        </Container>
    );
}

export default LivestreamPage;